export default {
  methods: {
    validateRequirement () {
      this.validateRequiredFields()
    },
    validateRequiredFields () {
      this.toggleRequiredFieldsErrorStyle()
      this.checkRequiredFields()
      this.$emit('form-validity', this.checkRequiredFields())
    },
    getRequiredFields () {
      return [...document.querySelectorAll('.required')]
    },
    checkRequiredFields () {
      let requiredFields = this.getRequiredFields()
      return requiredFields.map(field => {
        return this.fieldsWithoutMultiselectSubfields(
          [...field.querySelectorAll('input, select, textarea')]
        )
          .map(field => {
            return this.getProperFields(field, 'check')
          })
      }).map(field => {
        return field[0]
      }
      ).every(field => {
        if (typeof field === 'object') {
          return field !== null
        }
        return field.length > 0
      })
    },
    showRequiredFields () {
      let requiredFields = this.getRequiredFields()
      return requiredFields.map(field => this.fieldsWithoutMultiselectSubfields([...field.querySelectorAll('input, select, textarea')])
        .filter(field => {
          return this.getProperFields(field, 'show')
        })
        .map(field => {
          if (field.labels[0]) {
            return field.labels[0]
          } else {
            let groupPrefix = this.getGroupPrefix(field)

            let label = document.createElement('label')
            label.innerText = (groupPrefix ? groupPrefix + ': ' : '') + document.getElementById(`task-${field.id.split('-')[1]}`).parentNode.parentNode.querySelector('label').innerText
            return label
          }
        })
        .map(e => {
          return e && e.innerText ? e.innerText : ''
        })
      ).filter(field => field.length !== 0)
        .join(', ')
    },
    toggleRequiredFieldsErrorStyle () {
      let requiredFields = this.getRequiredFields()
      const toggleBorder = (field) => {
        if (field === undefined || field.length === 0) {
          return
        }
        field.length !== 0 && field[0].value === '' ? field[0].setAttribute('style', 'border: 1px solid #f65c43') : field[0].removeAttribute('style', 'border')
      }
      requiredFields.filter(field => field.className.includes('field-multiselect') || field.className.includes('field-personMultiselect'))
        .map(field => [...field.querySelectorAll('.multiselect')])
        .forEach(field => {
          let input = field[0].querySelector('.multiselect__tags')
          if (field[0].innerText.trim().includes('wybierz') || field[0].innerText === '') {
            input.setAttribute('style', 'border: 1px solid #f65c43; border-radius: 3px;')
          } else {
            input.setAttribute('style', 'border: 1px solid #ccc; border-radius: 3px;')
          }
        })
      requiredFields.filter(field => !field.className.includes('field-multiselect') && !field.className.includes('field-personMultiselect')).map(field => [...field.querySelectorAll('input, select, textarea')]
        .filter(field => field.value !== 'undefined'))
        .forEach(field => {
          toggleBorder(field)
        })
    },
    fieldsWithoutMultiselectSubfields (field) {
      if (field.length !== 1) {
        return field.filter(subfield => {
          return [...subfield.classList].includes('multiselect__input')
        })
      } else {
        return field
      }
    },
    getGroupPrefix (field) {
      let group = this.schema.groups
        .map(group => {
          return [Object.values(group)[0], Object.values(group)[1]]
        }).filter(group => group[0][0].hasOwnProperty('baseCollectionFields') ? group[0][0].hasOwnProperty('baseCollectionFields') : group[0][1].hasOwnProperty('baseCollectionFields'))
        .map(group => {
          return [group[0][0].baseCollectionFields ? group[0][0].baseCollectionFields : group[0][1].baseCollectionFields, group[1]]
        }).filter(group => group[0]
          .filter(baseField =>
            baseField.inputName === field.id.split('-')[1].split(baseField.collectionIndex).join('i')).length !== 0
        )
      return group.length === 0 ? null : group[0][1]
    },
    getProperFields (field, mode) {
      if ([...field.classList].filter(className => className.includes('multi')).length !== 0) {
        if (field.id.includes('[')) {
          let fieldModelName = field.id.split('-')[1]
          let fieldCollectionIteration = fieldModelName.split('[')[1].split(']')[0]
          let collectionField = fieldModelName.split('.')[1]

          let value = this.model[fieldModelName.split('[')[0]][fieldCollectionIteration][collectionField]

          let switchCase = {
            check: value,
            show: value === 'undefined' || value === null || value.length === 0
          }

          return switchCase[mode]
        } else {
          let switchCase = {
            check: this.model[field.id.split('-')[1]],
            show: this.model[field.id.split('-')[1]] === 'undefined' || this.model[field.id.split('-')[1]] === null || this.model[field.id.split('-')[1]].length === 0
          }

          return switchCase[mode]
        }
      } else {
        let switchCase = {
          check: field.value,
          show: field.value === 'undefined' || field.value.length === 0
        }

        return switchCase[mode]
      }
    }
  }
}
